const brandColors = {
  primary: '#00A3DF',
  secondary: '#00A3DF',
};

const sidebarColors = {
  backgroundColor: '#EDF7FB',
  color: '#022842',
  dotfileLogo: '#022842',
};

const linkColors = {
  color: '#00A3DF',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
